
<div id="login-modal">
    <form name="form" auto-focus>
        <div class="modal-header">
            <h3 class="modal-title">Log in</h3>

            <button type="button" class="btn-close" (click)="cancelLogin()" *ngIf="!kwCfg.authRequired">&nbsp;</button>
            <div [hidden]="!samlAllowLocalLogin" (click)="samlLocalLogin = !samlLocalLogin"
                class="float-end login-context-switch">
                [ {{samlLocalLogin ? "Switch to User Login" : "Switch to Staff Login"}} ]
            </div>
        </div>

        <div class="modal-body">
            <p [hidden]="!loginOptions.sessionExpired" class="loginText">Your session has expired. Please log in again to
                continue.</p>
            <div *ngIf="saml" [hidden]="!!samlLocalLogin">
                <iframe class="saml-iframe" src="/api/saml/login"></iframe>
            </div>
            <div [hidden]="!!saml || samlLocalLogin">
                <p [hidden]="loginOptions.sessionExpired" id="loginText">Log in to place holds and manage
                    your account.</p>
                <div class="mb-3">
                    <label for="login-modal-user">Username</label>
                    <input id="login-modal-user" type="text" class="form-control"
                        [(ngModel)]="logindata.username" name="username" autocomplete="username" autofocus>
                </div>
                <div class="mb-3">
                    <label for="login-modal-pwd">Password</label>
                    <input id="login-modal-pwd" type="password" class="form-control" 
                        [(ngModel)]="logindata.password" name="password" autocomplete="current-password">
                </div>

            </div>
            <div class="alert" [hidden]="!loginFailed">
                <p id="login-modal-failure-text" class="text-danger">The username or password you entered is incorrect. Please try again. <span
                        class="forgot-password" *ngIf="kwCfg.OpacPasswordChange"><a href
                            (click)="lostPassDlgOpen()">Forgot Password?</a></span></p>
            </div>
            <div class="alert alert-warning" [hidden]="!loggingIn">
                <p>Logging in...</p>
            </div>
        </div>
        <div class="modal-footer" [hidden]="!!saml || samlLocalLogin">
            <button type="submit" [disabled]="loggingIn" (click)="login()" class="btn btn-primary login">Sign in</button>
        </div>
    </form>
</div>
