import { Inject, Injectable } from '@angular/core';
// import { downgradeInjectable, getAngularJSGlobal } from '@angular/upgrade/static';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from './login/login-modal.component';

// Downgradable service to open modals.


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private ngbModalService: NgbModal
    ) { }

    login() : NgbModalRef {
        const modalRef = this.ngbModalService.open( LoginModalComponent );
        modalRef.result.then( () => {
        }, (err) => {
            // FIXME: may need to upgrade httpResendQueue and call .rejectAll().
            console.warn('closing');
            return false;
        })
        return modalRef;
    }

}

// getAngularJSGlobal().module('kohapac').factory('modalService', downgradeInjectable(ModalService));
