import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent, getAngularJSGlobal } from '@angular/upgrade/static';
import { KOHA_CONFIG_SVC, KOHA_DLG_SVC, KOHA_USER_SVC } from 'src/app/upgrade/ng1-providers';

@Component({
    selector: 'app-login-button',
    templateUrl: 'login-button.component.html',
    styles: [
    ]
})
export class LoginButtonComponent implements OnInit {

    config: any = {}
    user: any = {}

    listDlgOpen() : void {
        this.kohaDlg.lists();
    }
    lostPass() : void {
        this.kohaDlg.lostPass();
    }
    loginDlgOpen(): void {
        this.kwUserSvc.whenAuthenticatedUser( { showAuthError : false } ).then( 
            (uid: any) => { console.log(uid) },
            (huh: any) => {  } );
    }

    samlRedirectLogin : Function|undefined
    samlRedirectLogout : Function|undefined
    samlAllowMenuLocalLogin = false
    samlAllowMenuLocalLogout = false

    setLoginBranch(): void {

        let branches:any[] = this.kwConfig.interpolator('branch').listCodes()
            .filter( (code: string) => { return this.kwUserSvc.canInBranch({parameters: 'set_branch'}, code) })
            .map( ( code: string) => { 
                    return { 
                        branchcode: code,
                        branchname: this.kwConfig.interpolator('branch').display(code)
                    } } );

            this.kohaDlg.dialog({
                heading: "Change Login Location",
                message: "Please select your library.",
                inputs: [{
                    name: 'branch',
                    label: 'Library',
                    val: this.kwUserSvc.login_branch,
                    options: 'b.branchcode as b.branchname for b in branches'
                }],
                scopevars: { branches : branches },
                buttons: [{
                    val: true,
                    label: 'Save',
                    btnClass: "btn-primary",
                    submit: true
                }, {
                    val: false,
                    label: 'Cancel'
                }]
            })
                .result.then( (val : any) => {
                    if (val) {
                        this.kwUserSvc.setLoginBranch(val.branch).catch(function(e: any){
                            console.warn(e);
                        });
                    }
                }); 
    }

    constructor(
        @Inject(KOHA_CONFIG_SVC) private kwConfig: any,
        @Inject(KOHA_USER_SVC) private kwUserSvc: any,
        @Inject(KOHA_DLG_SVC) private kohaDlg: any
    ) {
    }

    ngOnInit(): void {
        this.config = this.kwConfig;

        if (this.kwConfig.external_auth.saml && !this.kwConfig.external_auth.saml.iframe) {
            this.samlRedirectLogin = function() {
                window.location.assign('/api/saml/login');
            };
            this.samlAllowMenuLocalLogin = this.kwConfig.external_auth.saml.local_login;
        }

        if (this.kwConfig.external_auth.saml && this.kwConfig.external_auth.saml.sso_logout) {
            this.samlRedirectLogout = function() {
                window.location.assign('/api/saml/logout');
            };
            this.samlAllowMenuLocalLogout = this.kwConfig.external_auth.saml.local_logout;
        }

        this.kwUserSvc.observable.subscribe(
            (u : any) => { this.user = u }
        );
    }

}

getAngularJSGlobal().module('kohapac')
    .directive('appLoginButton', downgradeComponent({ component: LoginButtonComponent }));