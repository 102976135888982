import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent, getAngularJSGlobal } from '@angular/upgrade/static';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/core/alerts/alert.service';
import { KOHA_MODEL_SVC } from 'src/app/upgrade/ng1-providers';
import { PaypalComponent } from '../paypal/paypal.component';

@Component({
    selector: 'app-payment-button',
    template: `
    <a class="btn btn-success" role="button" (click)="openPaymentView()" >Make payment now</a>
    `,
    styles: [
    ]
})
export class PaymentButtonComponent implements OnInit {

    @Input () balance! : number
    @Output('refresh') refreshAccount: EventEmitter<any> = new EventEmitter()

    constructor(
        private offcanvasService: NgbOffcanvas,
        private alertService: AlertService,
        @Inject(KOHA_MODEL_SVC) private kwApi: any
    ) { }

    openPaymentView() {
        // get unique token for user.
        this.kwApi.Payment.paypalCustToken().$promise.then( ( custSess : any ) => {

            const pmtOffcanvasRef = this.offcanvasService.open(
                PaypalComponent, {
                    beforeDismiss : () => {
                        if(pmtOffcanvasRef.componentInstance.inProcess){
                            window.confirm( "Payment has not yet been processed.  Abandon?");
                            return false;
                        }
                        return true;
                    }
                });
                pmtOffcanvasRef.componentInstance.userToken = custSess.client_token;
                pmtOffcanvasRef.componentInstance.balance = this.balance;

            pmtOffcanvasRef.result.then(
                (rv) => {
                    if(rv?.payment){
                        this.alertService.success('Payment successfully processed.');
                        this.refreshAccount.emit();
                    }

                }, (err) => {
                    console.warn(err);
                    this.alertService.warning( err );
                })
        }).catch( (e:any) => {
            this.alertService.addApiError( e );
        });

    }

    ngOnInit(): void {
    }
}

getAngularJSGlobal().module('kohapac')
    .directive('appPaymentButton', downgradeComponent({ component: PaymentButtonComponent, propagateDigest: false }));