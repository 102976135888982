<style>

</style>

<div ng-controller="KohaCtrl" ng-cloak class="ng-cloak" ng-class="{'staff': user.is_staff, 'non-staff': !user.is_staff, 'staff-route': staffRoute, 'public-route': !staffRoute}">
    <span koha-loading="root.loading"></span>
    <div id="app-body">
      <span class="aria-live" id="aria-app-title" ng-bind="pageSubTitle" aria-live="assertive" aria-atomic="true"></span>
      <a id="nav-top"></a>
      <a id="skip-nav" href ng-click="skipToContent()" class="sr-only sr-only-focusable">Skip to main content</a>
      <div bv-masthead></div>
      <div id="mapCtrl" style="height: 0px; overflow: hidden">
        <div ng-mapsystem class='mapDiv ng-mapsystem'></div>
      </div>
      <app-alerts></app-alerts>

      <div id="maintenance-mode" ng-if="config.OpacMaintenance">
        <h2 class="error">System unavailable</h2>
        <p class="alert error">The system is currently down for maintenance. Please try again later.
        <p>
      </div>

      <div id="breadcrumbs" bv-global-breadcrumbs>
      </div>

      <div id="main-content" ng-class="{sandboxed: config.Sandbox && user.is_staff}" ng-if="!config.OpacMaintenance"
        role="main" class="clearfix">
        <div id="main-view" ui-view>
        </div>
      </div>

      <div ng-hide="hideHeaderFooter">
        <footer id="footer" ng-show="globalfooter.visible">
          <div class="footer" ng-bind-html="config.layout.footer_html"> </div>
        </footer>
      </div>
    </div>


</div>

<!-- <router-outlet></router-outlet> -->
<!-- insert router-outlet component here -->