import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppRoutingModule } from './app-routing.module';
import { UpgradeModule, getAngularJSGlobal, downgradeInjectable, setAngularJSGlobal } from '@angular/upgrade/static';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertsComponent } from './core/alerts/alerts.component';
import { PublicNavComponent } from './core/public-nav/public-nav.component';
import { configServiceProvider, dlgServiceProvider, mapServiceProvider, modelServiceProvider, userServiceProvider } from './upgrade/ng1-providers';

import * as Rx from 'rxjs';
import { LoginButtonComponent } from './core/login/login-button.component';
import { LoginModalComponent } from './core/login/login-modal.component';
import { ModalService } from './core/modal.service';
import { AppComponent } from './app.component';
import { UserDashboardModule } from './user-dashboard/user-dashboard.module';


declare global {
    interface Window {
        Rx: any
        globalRefs: any   // global ref to prevent downgraded components from build exclusion via tree-shaking
    }
}
if( ! window.globalRefs ) window.globalRefs = {};
window.globalRefs.app = [
    LoginButtonComponent,
    PublicNavComponent
];


@NgModule({
    declarations: [
        AppComponent,
        AlertsComponent,
        PublicNavComponent,
        LoginButtonComponent,
        LoginModalComponent

    ],
    imports: [
        BrowserModule,
        // AppRoutingModule,
        UpgradeModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        UserDashboardModule
    ],
    providers: [
        configServiceProvider,
        userServiceProvider,
        dlgServiceProvider,
        modelServiceProvider,
        mapServiceProvider
    ],
    entryComponents: [
        AppComponent
    ]
    // bootstrap: [AppComponent]     // [rch] manually done below
})
export class AppModule implements DoBootstrap {

    constructor(
        private upgrade: UpgradeModule,
        private titleService: Title) {

    }

    ngDoBootstrap(app: any) {

        window.Rx = Rx; // allow legacy koha-wack services to expose observables.
        app.bootstrap(AppComponent);
        console.log('Angular bootstrap.');
        setAngularJSGlobal(angular);

        const featuresLoaded = (KOHA.toLoad.length) ?
            KOHA.toLoad.map((resource: any) => { return resource.loaded }) : [Promise.resolve()];

        this.titleService.setTitle( KOHA.config.pageTitle );

        Promise.all(featuresLoaded).then(() => {
            getAngularJSGlobal().module('kohapac').factory('modalService', downgradeInjectable(ModalService));
            console.log('! -- BOOTSTRAPPING LEGACY ');
            this.upgrade.bootstrap(document.body, ['kohapac'], { strictDi: true });   // TODO: add { strictDi: true } after updating ng1 code.
        });

    }

}

/*

legacy angularJS load process :

script.loader 
 1  executes koha.js , which creates KOHA global, with default values.
 2  gets jsonp allconfig, passing to KOHA.extend_config() to set up config.
 3 creates list of modules to include at bootstrap.
 4 loads those module files or koha-wack.js
 5 bootstraps.


core bundles :
jquery-base
angular-core
bv-lgc-app

feature bundles : ngModule defined

archview : kohapac.archview
dls : kohapac.dls
geo : kohapac.geo
eds : kohapac.eds-app
mkws : kohapac.mkws-app
kiosk : kohapac.kiosk-app
cloudlibrary : [ no module ]


 */
