import { AfterViewChecked, Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent, getAngularJSGlobal } from '@angular/upgrade/static';
import { KOHA_CONFIG_SVC, KOHA_DLG_SVC, KOHA_USER_SVC, KOHA_MAP_SVC } from 'src/app/upgrade/ng1-providers';

@Component({
    selector: 'app-public-nav',
    template: `
<nav>

<div class="navbar navbar-expand-lg mb-3 navbar-dark bg-primary">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" (click)="burger = !burger">&#9776;</button>

    <div [ngbCollapse]="burger" class="collapse navbar-collapse">
      <ul class="navbar-nav mx-auto" (click)="burger=true">
          <li class="nav-item">
            <a class="nav-link homeLink" href="/">Home</a>
          </li>
          <li class="nav-item" [hidden]="!config.ext_svc.worldcat">
            <a  class="nav-link electronicResourcesLink" href="/app/resource/search">Electronic Resources</a>
          </li>
          <li class="nav-item">
            <a class="nav-link advSearchLink" href="/app/search">Advanced Search</a>
          </li>
          <li class="nav-item" [hidden]="!config.NewTitlesAge">
            <a href class="nav-link new-titles-link" [href]="newTitlesHref">New Titles</a>
          </li>
          <li class="nav-item" [hidden]="!config.OpacTopissue">
            <a class="nav-link most-pop-link" href="/app/search/*:*?sort=popularity%20desc">Most Popular</a>
          </li>
          <li class="nav-item" *ngIf="config.CourseReserves">
            <a class="nav-link course-reserves-link" href="/app/course-reserves">Course Reserves</a>
          </li>
          <li class="nav-item" [hidden]="!config.OpacAuthorities">
            <a class="nav-link auth-search-link" href="/app/authorities">Browse</a>
          </li>
          <li class="nav-item" [hidden]="!config.TagsEnabled">
            <a class="nav-link tag-cloud-link" href="/app/tag-cloud">Tag Cloud</a>
          </li>
          <li class="nav-item" [hidden]="!enabled.addDLSO">
            <a class="nav-link add-product-link" href="/app/batchManager">Add Content</a>
          </li>
          <li class="nav-item saved-searches" [hidden]="!enabled.savedSearch">
            <a class="nav-link saved-search-link" href (click)="savedSearchDlgOpen()">Saved Searches</a>
          </li>

          <li class="nav-item virtualshelves" [hidden]="!config.virtualshelves">
              <a class="nav-link shelves-list-link" href (click)="listDlgOpen()">
                  <span class="lists-label"> Lists </span>
              </a>
          </li>
          <!-- <li class="nav-item rfi" ngbDropdown [hidden]="user.loggedin && rfi_types">
              <a class="rfi-link" href ngbDropdownToggle>RFI <span class="caret faded"></span></a>
              <ul ngbDropdownMenu>
                  <li ngbDropdownItem  ng-repeat="code in rfi_types">
                      <a href ng-click="rfiDlgOpen(code)">{{code | displayName:'callslip_types'}}</a>
                  </li>
              </ul>
          </li> -->
          <li class="nav-item maplaunch" *ngIf="config.geospatialSearch">
              <a href class='nav-link kohaMapBtn' (click)="toggleMap()"><i class='bi icon-large bi-globe-americas'></i>Map</a>
          </li>
          <li class="nav-item" *ngIf="enabled.selfRegister">
            <a class="nav-link self-register-link" href="/app/self-register" title="New patrons register to log in">Sign up!</a>
          </li>
      </ul>
    </div>
  </div>

</div>

</nav>
  `,
    styles: [
        '.navbar-default .navbar-nav>li>a { color: #ececec; }',
        '.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover { color: white; }'
    ]
})
export class PublicNavComponent implements OnInit, AfterViewChecked {

    public burger = true; // i.e. isMenuCollapsed
    config: any = {}
    newTitlesHref: string = '/app/search/*:*?fq=catdate:%5BNOW-30DAYS%20TO%20*%5D&sort=catdate%20desc'
    enabled: any = {
        addDLSO: false,
        savedSearch: false
    }
    toggleMap(): void {
        if(this.cfg.geospatialSearch) this.mapSvc.toggleMap();
    }
    listDlgOpen(): void {
        this.kohaDlg.lists();
    }
    savedSearchDlgOpen(): void {
        this.kohaDlg.savedSearch();
    }

    constructor(
        @Inject(KOHA_CONFIG_SVC) private cfg: any,
        @Inject(KOHA_USER_SVC) private userSvc: any,
        @Inject(KOHA_MAP_SVC) private mapSvc: any,
        @Inject(KOHA_DLG_SVC) private kohaDlg: any
    ) {

    }

    ngOnInit(): void {
        this.config = this.cfg;

        if (this.cfg.NewTitlesAge != 30){
            this.newTitlesHref = this.newTitlesHref.replace('30DAYS', this.cfg.NewTitlesAge + 'DAYS');
        }

        this.userSvc.observable.subscribe(
            (user: any) => {
                this.enabled.savedSearch = (user.loggedin && user.savedSearches.all.length);
                this.enabled.addDLSO = (this.cfg.dls.enabled && user.can({ editcatalogue: { dlso: 'upload' } }, '*'));
                this.enabled.selfRegister = (!user.loggedin && this.cfg.patronSelfRegister);
            }
        )

        //    TODO: mk callslip component.
        // if(configService.interpolator('callslip_types')){
        //     $scope.rfi_types = configService.interpolator('callslip_types').listCodes()
        //         .filter(function(ctype){ return ctype != 'callslip' && ctype != 'doc_del' ;});
        // }

    }


    ngAfterViewChecked(): void {
        // console.log('ngAfterViewChecked... (in public-nav)');
    }
}

getAngularJSGlobal().module('kohapac')
    .directive('appPublicNav', downgradeComponent({ component: PublicNavComponent }));
