import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentButtonComponent } from './payment-button/payment-button.component';
import { PaypalComponent } from './paypal/paypal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

if( ! window.globalRefs ) window.globalRefs = {};
window.globalRefs.dash = [
    PaymentButtonComponent,
    PaypalComponent
];


@NgModule({
  declarations: [
    PaymentButtonComponent,
    PaypalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
]
})
export class UserDashboardModule { }
