import { Component } from '@angular/core';
import { AlertService } from './alert.service';

@Component({
    selector: 'app-alerts',
	template: `
		<ngb-toast *ngFor="let alert of alertService.alerts"
			[class]="alert.classname"
			[autohide]="!alert.persist"
			[delay]="timeout"
            [header]="alert.headerText"
			(hidden)="alertService.dismiss(alert)"
		>
            <div class="alert-body" [innerHtml]="alert.message">
            </div>

		</ngb-toast>
	`,
	host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
    styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent {

    timeout: number = this.alertService.timeout
    constructor( public alertService: AlertService) {
    }

}
