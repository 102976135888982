import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { HostedFieldsHandler, loadScript, OrderResponseBody, PayPalNamespace } from '@paypal/paypal-js';

import { KOHA_CONFIG_SVC, KOHA_MODEL_SVC, KOHA_USER_SVC } from 'src/app/upgrade/ng1-providers';
import { AlertService } from 'src/app/core/alerts/alert.service';


@Component({
    selector: 'app-paypal',
    templateUrl: './paypal.component.html',
    styles: [
        `
        .paypal-field {
            height: 2.5em;
        }
         `
    ]
})
export class PaypalComponent implements OnInit {

    inProcess = false;
    pmtAmt = new FormControl('', [Validators.min(0), Validators.required]);
    nameOnCard = new FormControl('', [Validators.required]);
    @Input() balance!: number;
    userToken: string = '';
    sandbox = !this.kwConfig.pmtGatewayCfg.paypal.production;
    submitCard = () => {};
    noHostedFields = false;

    constructor(
        public activeOffcanvas: NgbActiveOffcanvas,
        private alertSvc: AlertService,
        @Inject(KOHA_CONFIG_SVC) private kwConfig: any,
        @Inject(KOHA_USER_SVC) private kwUserSvc: any,
        @Inject(KOHA_MODEL_SVC) private kwApi: any
    ) { }

    private enableUI = ( state=true ) => {
        this.inProcess = !state;
        if(state){
            this.pmtAmt.enable();
        } else {
            this.pmtAmt.disable();
        }
    }

    private renderPaypal(paypal: PayPalNamespace) {

        let ppOrder = {
            purchase_units: [{
                amount: {
                    value: this.pmtAmt.value!
                },
                custom_id: this.kwUserSvc.id + '' // hopefully doesn't need to be unique.
            }]
        };

        if(paypal.HostedFields){
            console.warn(paypal.HostedFields.isEligible());
        } else {
            console.warn('Paypal : Hosted fields unavailable.');
            this.noHostedFields = true;
        }

        paypal.Buttons?.({
            style: {
                layout: 'vertical',
                color: 'silver',
                shape: 'pill',
                label: 'paypal'
            },
            onInit: (data, actions) => {
                console.warn('paypal onInit');

            },
            createOrder: (data, actions) => {
                this.pmtAmt.disable();
                return actions.order.create( ppOrder );
            },

            // Finalize the transaction after payer approval
            onApprove: (data, actions) => {
                if (!actions.order) return Promise.reject();

                return actions.order.capture().then( (orderData: OrderResponseBody) => {
                // FIXME: Capture should happen on backend;  do intent: authorize now,
                // then call capture on backend to avoid potential loss .
                // this code handles paypal tx, not cc tx.

                    const transaction: any = orderData.purchase_units[0].payments?.captures?.[0];

                    if (transaction) {
                        this.kwApi.Payment.webPay( {
                            amount: transaction?.amount?.value,
                            order_id: orderData.id
                        } ).$promise.then( (  ) => {
                            this.enableUI();
                            this.activeOffcanvas.close( { payment: true });
                        }, () => {
                            this.alertSvc.error( { msg: "In-process payment error." , persist : true });
                            // payment captured, but not recorded.
                            this.activeOffcanvas.dismiss( { error: true });
                        });

                    } else {
                        console.warn("Payment transaction capture failed.");
                        this.activeOffcanvas.dismiss( { error: true });
                    }

                });
            },
            onCancel: () => {
                this.enableUI(  );
            },
            onError: (err: any) => {
                console.warn(err);
                this.enableUI( );
                this.activeOffcanvas.dismiss();
            }
        }).render('#paypal-button-container');  // end paypal.Buttons();
        if (paypal.HostedFields && paypal.HostedFields.isEligible()) {
            let orderId: string;
            paypal.HostedFields.render({
                createOrder: () => {
                    // return promise resolving to orderId.
                    return this.kwApi.Payment.createWebpayOrder(
                        {
                            amount : this.pmtAmt.value
                        } ).$promise
                        .then( ( rsp: any ) => {  // Fixme - add $resource typings.
                            console.log(rsp);
                            orderId = rsp.id;
                            return rsp.id;
                         } );

                },
                fields: {
                    number: {
                        selector: "#pp-card-number",
                        placeholder: "4111 1111 1111 1111",
                    },
                    cvv: {
                        selector: "#pp-cvv",
                        placeholder: "***",
                    },
                    postalCode: { // FIXME: this is not submitted with other data.
                        selector: "#pp-zipcode",
                        placeholder: '97212'
                    },
                    expirationDate: {
                        selector: "#pp-expiration-date",
                        placeholder: "MM/YY",
                    },
                },
            }).then((cardFields) => {

                this.submitCard = ( ) => {
                    this.enableUI( false );
                    cardFields.submit({
                        cardholderName: this.nameOnCard.value,
                        email: this.kwUserSvc.email
                    }).then( () => {
                        this.kwApi.Payment.webPay({
                            capture: true,
                            order_id: orderId
                        }).$promise.then( (orderdata: any) => {
                            // User fines are refreshed when pmt offcanvas is closed.
                            this.enableUI();
                            this.activeOffcanvas.close({payment: true});
                        }, ( huh: any ) => {
                            this.enableUI();
                            this.activeOffcanvas.dismiss( { error: huh })
                        } )
                    }, (err) => {
                        console.warn(err);
                        this.enableUI();
                        this.activeOffcanvas.dismiss( { error: err } );
                    });
                    return false;
                }
            });
        }
    }

    ngOnInit(): void {

        if (!this.kwConfig.pmtGatewayCfg.paypal.enable) return;

        this.pmtAmt.setValue(this.balance.toString());
        this.pmtAmt.addValidators([Validators.max(this.balance), Validators.min(0.99)]);

        if (window.paypal) {
            console.log('paypal obj already defined !');
            this.renderPaypal(window.paypal);
        } else {

            loadScript({
                "client-id": this.kwConfig.pmtGatewayCfg.paypal.client_id,
                "data-client-token": this.userToken,
                // TODO: Allow hosted-fields on/off.
                "components": "buttons,hosted-fields"
            }).then((paypal) => {
                console.log(paypal);
                if (paypal) this.renderPaypal(paypal);
            }).catch((err) => {
                console.warn(err);
                this.activeOffcanvas.dismiss( { error: "Sorry! Payments cannot be processed at this time."});
            })
        }
        // cleanup ??
    }

}
