import { InjectionToken } from "@angular/core";

export const KOHA_CONFIG_SVC = new InjectionToken<any>('KOHA_CONFIG_SVC');
export function createConfigService(i: { get: (arg0: string) => any; }) {
    return i.get('configService');
}

export const configServiceProvider = {
    provide: KOHA_CONFIG_SVC,
    useFactory: createConfigService,
    deps: ['$injector']
}


export const KOHA_USER_SVC = new InjectionToken<any>('KOHA_USER_SVC');
export function createUserService(i: { get: (arg0: string) => any; }) {
    return i.get('userService');
}

export const userServiceProvider = {
    provide: KOHA_USER_SVC,
    useFactory: createUserService,
    deps: ['$injector']
}


export const KOHA_DLG_SVC = new InjectionToken<any>('KOHA_DLG_SVC');
export function createDlgService(i: { get: (arg0: string) => any; }) {
    return i.get('kohaDlg');
}

export const dlgServiceProvider = {
    provide: KOHA_DLG_SVC,
    useFactory: createDlgService,
    deps: ['$injector']
}


export const KOHA_MODEL_SVC = new InjectionToken<any>('KOHA_MODEL_SVC');
export function createModelService(i: { get: (arg0: string) => any; }) {
    return i.get('kwApi');
}

export const modelServiceProvider = {
    provide: KOHA_MODEL_SVC,
    useFactory: createModelService,
    deps: ['$injector']
}

export const KOHA_MAP_SVC = new InjectionToken<any>('KOHA_MAP_SVC');
export function createMapService(i: { get: (arg0: string) => any; }) {
    try {
        return i.get('mapComptrollerSvc');
    } catch {
        return {};
    }
}

export const mapServiceProvider = {
    provide: KOHA_MAP_SVC,
    useFactory: createMapService,
    deps: ['$injector']
}

// Expose legacy services in Angular.
