import { Injectable, TemplateRef } from '@angular/core';
import { downgradeInjectable, getAngularJSGlobal } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private curId: number = 0
    public alerts: Alert[] = []
    timeout: number = 7100

    constructor() { }

    public add(alertSpec: AlertSpec|string) {

        let alert = new Alert(alertSpec);  //default type info
        this.alerts.push(alert);

    }

    public success(alertSpec: AlertSpec|string){
        if(typeof alertSpec === "string") alertSpec = { msg: alertSpec };
        this.add( { ...alertSpec, type: "success" } );
    }
    public error(alertSpec: AlertSpec|string){
        if(typeof alertSpec === "string") alertSpec = { msg: alertSpec };
        this.add( { ...alertSpec, type: "error" } );
    }
    public warning(alertSpec: AlertSpec|string){
        if(typeof alertSpec === "string") alertSpec = { msg: alertSpec };
        this.add( { ...alertSpec, type: "warning" } );
    }


    addApiError(err: any, context?: string) {
        // ng1
        let str;
        if ((typeof (err) === 'object') && err.data) {
            str = err.data;
        }
        else if ((typeof (err) === 'object') && err.statusText) {
            str = err.statusText;
        }
        else if (typeof (err) === 'object') {
            str = 'API Error';
        }
        else {
            str = '' + err;
        }

        if (context) {
            str = context + ': ' + str;
        }
        this.add({ msg: str, type: 'error' });
    }

    dismiss(alert: Alert) {
        this.alerts = this.alerts.filter((t) => t !== alert);
    }

    setTimeout(n: number): void {
        console.log("Set timeout = " + n);
        this.timeout = n * 1000;
    }
    getTimeout(): number {
        return Math.floor(this.timeout / 100) / 10;
    }

}

const alertTypes = {
    "info": {
        header: "Notice",
        classname: ""
    },
    "error": {
        header: "Error",
        classname: "bg-danger text-light"
    },
    "warning": {
        header: "Warning",
        classname: "bg-warning text-light"
    },
    "success": {
        header: "Success",
        classname: "bg-success text-light"
    }
}

export class Alert {
    classname = ""
    persist = false
    // tmpl?: TemplateRef<any>     // [TODO]
    message?= ""
    headerText = "Notice"

    constructor(def: AlertSpec|string) {
        if(typeof def === "string") def = { msg: def };
        let alertType = def.type || "info";
        this.headerText = alertTypes[alertType].header;
        this.classname = alertTypes[alertType].classname;
        if (def.persist) this.persist = def.persist;
        this.message = def.msg;

    }
}
// legacy interface.
interface AlertSpec {
    type?: "info" | "error" | "success" | "warning"
    msg: string
    header?: string
    persist?: boolean
}

console.log('get ajs global.');
getAngularJSGlobal().module('kohapac').factory('alertService', downgradeInjectable(AlertService));
