import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/core/alerts/alert.service';
import { KOHA_CONFIG_SVC, KOHA_DLG_SVC, KOHA_USER_SVC } from 'src/app/upgrade/ng1-providers';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styles: [
    ]
})
export class LoginModalComponent implements OnInit, OnDestroy {

    constructor(
        public activeModal: NgbActiveModal,
        @Inject(KOHA_CONFIG_SVC) public kwCfg: any,
        @Inject(KOHA_USER_SVC) public kwUser: any,
        @Inject(KOHA_DLG_SVC) public kwDlg: any,
        private alertService: AlertService
    ) {
    }

    saml = false
    samlLocalLogin = false
    samlAllowLocalLogin : boolean = this.kwCfg.external_auth.saml?.local_login || false

    canChangePW = this.kwCfg.OpacPasswordChange || false


    loginFailed = false
    loggingIn = false
    logindata = { username: '', password: '' }
    loginOptions = { sessionExpired : false }

    lostPassDlgOpen = this.kwDlg.lostPass

    cancelLogin() {
        this.kwUser.loginDlgOpened = false;
        this.activeModal.dismiss(false);
    }

    login() {
        this.loggingIn = true;
        this.loginFailed = false;

        this.kwUser.login(this.logindata.username, this.logindata.password).then( (userid: number) => {
            this.loginFailed = false;
            this.loggingIn = false;

            this.alertService.add({
                type: "success",
                msg: "You've successfully logged in!<p>Go to <a href=\"/app/me/dashboard\">your account</a>.<p>"
            });

            this.activeModal.close(userid);
            this.kwUser.loginDlgOpened = false;

        }, ( error: any ) => {
            this.loginFailed = true;
            this.loggingIn = false;
        })
    }


    ngOnInit(): void {

        if (this.kwCfg.external_auth.saml?.iframe) {
            let handleMessage = (e: any) => {
                try {
                    let obj = JSON.parse(e.data);
                    console.dir(obj);
                    if ('samlCancel' in obj) {
                        this.cancelLogin();
                    } else if (obj.samlError) {
                        this.alertService.add({
                            type: 'error',
                            msg: ("Login error: " + obj.samlError),
                        });
                        this.cancelLogin();
                    } else if (obj.samlSuccess) {
                        this.alertService.add({
                            type: "success",
                            msg: "You've successfully logged in!<p>Go to <a href=\"/app/me/dashboard\">your account</a>.<p>"
                        });
                        this.kwUser.externalAuthInit().then(() => {
                            this.kwUser.loginDlgOpened = false;
                            this.activeModal.close(this.kwUser.id);
                        });
                    }
                    window.removeEventListener('message', handleMessage);
                }
                catch (e) {
                    console.log(e);
                }
            };

            window.addEventListener('message', handleMessage);
        }

    }

    ngOnDestroy(): void {
        this.kwUser.loginDlgOpened = false;
    }

    ngAfterViewInit(): void {
        this.kwCfg.runUserJs();
    }


}
