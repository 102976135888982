<div class="offcanvas-header">
    <h5 class="offcanvas-title">Make payment [ Balance: {{balance | currency}} ]</h5>
    <button type="button" class="btn-close text-reset" aria-label="Close" (click)="activeOffcanvas.close()"></button>
</div>
<div class="offcanvas-body">

    <form name="ppBalance" class="my-4 row">
        <label for="pmt-amt" class="col col-form-label">Payment amount</label>
        <div class="col">
            <input id="pmt-amt" type="number" step=".01" class="form-control" [formControl]="pmtAmt">
            <div *ngIf="pmtAmt.invalid" class="alert text-danger">
                <div *ngIf="pmtAmt.errors">
                    Invalid payment amount.
                </div>
            </div>
        </div>
    </form>
    <div class="alert alert-danger" *ngIf="sandbox">Test Only Mode</div>
    <div class="card paypal-credit-card" [hidden]="noHostedFields">
        <div class="card-header">
            Pay with credit card
        </div>
        <div class="card-body">
            <div class="paypal-hosted-fields">
                <form id="card-form" class="" (ngSubmit)="submitCard()">
                    <label class="form-label" for="pp-card-number">Card Number</label>
                    <div id="pp-card-number" class="form-control paypal-field"></div>
                    <div class="row">
                        <div class="col">
                            <label class="form-label" for="pp-expiration-date">Expiration Date</label>
                            <div id="pp-expiration-date" class="form-control paypal-field"></div>
                        </div>
                        <div class="col">
                            <label class="form-label" for="pp-cvv">CVV</label>
                            <div id="pp-cvv" class="form-control paypal-field"></div>
                        </div>
                    </div>
                    <label class="form-label" for="pp-card-holder-name">Name on Card</label>
                    <input type="text" id="pp-card-holder-name" class="form-control" [formControl]="nameOnCard">
                    <label class="form-label" for="pp-zipcode">Zipcode</label>
                    <div id="pp-zipcode" class="form-control paypal-field"></div>
                    <button type="submit" class="btn btn-primary my-4" [disabled]="inProcess">Submit Payment</button>
                </form>
            </div>
        </div>
    </div>

    <div class="funding-separator text-center my-4"> &mdash; OR &mdash; </div>

    <div id="paypal-button-container" class="paypal-standard"></div>


    <button type="button" class="btn btn-outline-secondary float-end my-4"
        (click)="activeOffcanvas.close()">Cancel</button>
</div>